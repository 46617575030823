<template>
  <div class="page-container">
    <el-form :inline="true">
      <div class="row-bg">
        <el-row :gutter="24">
          <el-col :lg="12" :md="24">
            <el-form-item label="选择校区：">
              <el-select v-model="formData.campusIds" multiple filterable placeholder="请选择" class="w100" clearable disabled>
                <el-option
                  v-for="item in campusIdsList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :lg="12" :md="24">
            <el-form-item label="过滤班级：">
              <el-select
                v-model="formData.classIdsExclude"
                multiple
                filterable
                clearable
                placeholder="请选择"
                class="w100"
                disabled
              >
                <el-option
                  v-for="item in classIdsExcludeList"
                  :key="item.class_id"
                  :label="item.name"
                  :value="item.class_id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <template v-if="advanced">
          </template>
          <!-- <search-query
            @updateView="updateView"
            @clearQuery="formData = { };search()"
            @searchQuery="search"
            :showMore="false"
            queryText="查 询"
          ></search-query> -->
        </el-row>
      </div>
    </el-form>

    <!-- 表格 -->
    <el-card shadow="never">
      <div slot="header" class="flex" style="align-items: center;">
        <!-- <span></span> -->
        <div class="flex_1"></div>
        <download-excel :data="dataList" :fields="json_fields" name="校区薪酬统计报表.xls">
          <el-button type="primary" size="mini" style="margin-right: 10px;">导出报表</el-button>
        </download-excel>
        <text-button
          icon="el-icon-refresh"
          contentTit="刷新"
          @click.native="search"
        ></text-button>
        <text-button
          icon="el-icon-setting"
          contentTit="列设置"
          :colSetting="colSetting"
          @update="update"
          :ispopover="true"
          :baseColSetting="baseColSetting"
        ></text-button>
      </div>

      <el-table-bar fixed :static="true">
        <el-table
          :key="tableKey"
          :data="dataList"
          fit
          :highlight-current-row="true"
        >
          <el-table-column
            v-for="item of defaultHeader"
            :key="item.label"
            :label="item.label"
            :align="item.align ? item.align : 'left'"
            :min-width="item.width ? item.width : 'auto'"
            :sortable="item.sortable ? item.sortable : false"
            :prop="item.prop"
            :show-overflow-tooltip="true"
          >
            <template slot-scope="scope">
              <template v-if="item.label === '总课时'">
                <span v-text="changeHourMinutestr(scope.row.lessonLong)"></span>
              </template>
              <template v-else-if="item.label === '操作'">
                <el-button type="text" @click="details(scope.row)">查看详情</el-button>
              </template>
              <span v-else>{{ scope.row[item.prop] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>

      <el-pagination
        class="fr-pagination"
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pagination.currentPage"
        :page-sizes="pagination.pageSizes"
        :page-size="pagination.pageSize"
        :layout="pagination.layout"
        :total="pagination.total"
      ></el-pagination>

    </el-card>
  </div>
</template>

<script>
  import TextButton from '@/components/TextButton'
  import SearchQuery from '@/components/SearchQuery'
  import { getCampusAll } from '@/api/senate/campus'
  import { getSumSalaryByCampus } from '@/api/salary'
  import { getClassAll } from '@/api/senate/class'
  import JsonExcel from 'vue-json-excel'

  // 默认展示列
  const baseColSetting = [
    {
      label: '校区名',
      prop: 'campusTitle',
      state: true,
      align: 'center',
      width: '150'
    },
    {
      label: '总课时',
      prop: 'lessonLong',
      state: true,
      align: 'center',
      width: '100'
    },
    {
      label: '总出勤人数',
      prop: 'attend',
      state: true,
      align: 'center',
      width: '100'
    },
    {
      label: '总课酬',
      prop: 'lessonSalary',
      state: true,
      align: 'center',
      width: '100'
    },
    {
      label: '总基础薪资',
      prop: 'baseSalary',
      state: true,
      align: 'center',
      width: '100'
    },
    {
      label: '操作',
      state: true,
      align: 'center',
      width: '100'
    }
  ]
  export default {
    components: { TextButton, SearchQuery, downloadExcel: JsonExcel },
    created() {
      let datas = JSON.parse(this.$route.query.param)
      if (datas.campusIds && datas.campusIds.lenght !== 0) {
        this.formData.campusIds = datas.campusIds
      }
      if (datas.classIdsExclude && datas.classIdsExclude.lenght !== 0) {
        this.formData.classIdsExclude = datas.classIdsExclude
      }
      this.isProps = false
      this.getCampusAll()
      this.getClassAll()
      this.getSumSalaryByCampus(datas)
    },
    computed: {
      defaultHeader() {
        return this.colSetting.filter(item => item.state)
      },
    },
    data() {
      return {
        dataList: [],
        tableKey: 1,
        queryParam: {
          // 查询参数
          isFinish: false,
        },
        colSetting: JSON.parse(JSON.stringify(baseColSetting)), // 表格展示列
        baseColSetting: JSON.parse(JSON.stringify(baseColSetting)),
        pagination: {
          currentPage: 1,
          pageSize: 10,
          pageSizes: [10, 50, 100, 200, 400],
          layout: 'total, sizes, prev, pager, next, jumper',
          total: 0,
        },
        advanced: false,
        formData: {},
        campusIdsList: [],
        classIdsExcludeList: [],
        json_fields: {
          '校区名': 'campusTitle',
          '总课时': 'lessonLong',
          '总出勤人数': 'attend',
          '总课酬': 'lessonSalary',
          '总基础薪资': 'baseSalary',
        },
      }
    },
    methods: {
      async getCampusAll () {
        const res = await getCampusAll()
        this.campusIdsList = res.body
      },

      changeHourMinutestr(str) {
        if (str % 60 === 0) {
          return str / 60
        } else {
          return Number(str / 60).toFixed(2)
        }
      },

      search() {
        this.isProps = true
        this.pagination.currentPage = 1
        this.getSumSalaryByCampus()
      },

      async getClassAll () {
        const res = await getClassAll({
          pageNum: 1,
          pageSize: 1000,
          campusIdSet: this.formData.campusIds,
          minLessonTime: this.$route.query.min,
          maxLessonTime: this.$route.query.max,
          needCount: true
        })
        this.classIdsExcludeList = res.body.list
      },

      async getSumSalaryByCampus(data) {
        if (!data) {
          const res = await getSumSalaryByCampus({
            ssid: this.$route.query.id,
            month: this.$route.query.month,
            pageNum: this.pagination.currentPage,
            pageSize: this.pagination.pageSize,
            classIdsExclude: this.formData.classIdsExclude ? this.formData.classIdsExclude : null,
            campusIds: this.formData.campusIds ? this.formData.campusIds : null
          })
          this.dataList = res.body.list
          this.pagination.total = res.body.total
        } else {
          let datas = {
            ...data,
            month: this.$route.query.month,
          }
          const res = await getSumSalaryByCampus(datas)
          this.dataList = res.body.list
          this.pagination.total = res.body.total
        }
      },

      updateView(data) {
        this.advanced = data
      },

      details(row) {
        this.$router.push({
          path: '/statistics/campus-details',
          query: {
            id: this.$route.query.id,
            month: this.$route.query.month,
            classCampusId: row.campusId,
            param: this.$route.query.param,
            title: row.campusTitle
          }
        })
      },

      update(data) {
        this.tableKey = this.tableKey + 1
        if (data.lenght !== 0) {
          this.colSetting.forEach(item => {
            item.state = false
            data.forEach(ele => {
              if (ele === item.label && !item.state) {
                item.state = true
              }
            })
          })
        } else {
          this.colSetting = []
        }
      },

      // 分页
      handleCurrentChange(val) {
        this.pagination.currentPage = val
        this.getSumSalaryByCampus()
      },
      handleSizeChange(val) {
        this.pagination.pageSize = val
        this.getSumSalaryByCampus()
      }
    },

  }
</script>

<style lang="scss" scoped>
@import '~@/style/container.scss';

/deep/ .el-form--inline .el-form-item {
  margin-right: 0;
}
</style>
